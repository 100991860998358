import { GetServerSidePropsContext } from 'next';

export const REVALIDATION_THRESHOLD = 120;
export const CACHE_MAX_AGE = 60;

/**
 * Ensures that the SSR page is cached on the CDN level and returns
 * a stale page while revalidating the cached version on the origin server.
 * https://vercel.com/docs/edge-network/caching#stale-while-revalidate
 */
export const setStaleWhileRevalidateCacheControl = (
  res: GetServerSidePropsContext['res'],
) => {
  res.setHeader(
    'Cache-Control',
    `public, s-maxage=${CACHE_MAX_AGE}, stale-while-revalidate=${REVALIDATION_THRESHOLD}`,
  );
};
