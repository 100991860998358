import { Badge } from '@sumup/circuit-ui';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

export const OutOfStockBadge = styled(Badge)<{ isOutOfStock?: boolean }>(
  ({ isOutOfStock = false }) =>
    css`
      ${isOutOfStock ? '' : 'visibility: hidden;'}
      background-color: var(--cui-bg-warning-strong);
      color: var(--cui-fg-on-strong);
    `,
);
