import Image from 'next/image';
import { useRouter } from 'next/router';
import { Body, Headline, spacing } from '@sumup/circuit-ui';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import NextLink from 'next/link';
import { Theme } from '@sumup/design-tokens';
import { useRef } from 'react';

import { ImageWrapper } from './ImageWrapper';
import { ProductName } from './ProductName';
import { ShortBenefits } from './ShortBenefits';
import { PriceComponent } from './Price';
import { ProductCardSizes } from './ProductCard.types';

import { DiscountBadge } from 'productSelection/components/DiscountBadge';
import {
  IDigitalProductDetails,
  IProduct,
  IProductType,
} from 'productSelection/types/products';
import { IProductCardContent } from 'productSelection/types/content';
import { Promotion } from 'shared/store/promotions/types';
import { getDiscountDisplayValue } from 'productSelection/services/discount';
import { getShopExperienceLink } from 'shared/utils/shop-experience-link';
import { parseTranslation } from 'shared/utils/translation';
import {
  dispatchMoreInfoClick,
  dispatchSkipShopEvent,
} from 'shared/services/tracker/events';
import {
  ExternalLink,
  InternalLink,
} from 'src/experiments/odl/components/Link';
import { AddToCartButton } from 'src/experiments/add-to-cart/AddToCartButton';
import { getCountryCodeFromISOLocale } from 'shared/i18n/helpers';
import { OutOfStockBadge } from 'productSelection/components/OutOfStockBadge';
import { TaxMessage } from 'productSelection/components/TaxMessage';
import { getUserId } from 'shared/services/optimizely';
import { getResizedUrl } from 'shared/services/imageUrl';
import { getMarginBottom } from 'productSelection/services/priceStyle';
import { Button } from 'src/experiments/odl/components/Button';
import {
  ODL_EXPERIMENT,
  VARIATION,
} from 'shared/services/optimizely/constants';

const DEFAULT_IMAGE_SIZE = 304;

export const PriceContainer = styled.div<{
  isOnBottomBar?: boolean;
  isPrimaryColor?: boolean;
  isVerticallyCenter?: boolean;
}>(
  ({
    theme,
    isOnBottomBar = false,
    isPrimaryColor = false,
    isVerticallyCenter = false,
  }) => css`
    display: flex;
    flex-direction: row;
    width: ${isOnBottomBar ? 'auto' : '100%'};
    align-items: flex-end;
    justify-content: center;
    ${isPrimaryColor ? `color: var(--cui-fg-accent)` : ''}
    ${isVerticallyCenter ? `margin: auto 0;` : ''}

    user-select: none;
    ${theme.mq.mega} {
      user-select: auto;
    }
  `,
);

export const StrikeThroughPrice = styled(Body)<{
  productCardSize?: ProductCardSizes;
}>(
  ({ theme, productCardSize }) => css`
    text-decoration: line-through;
    color: var(--cui-fg-subtle);
    margin-left: ${theme.spacings.byte};
    ${productCardSize !== undefined
      ? getMarginBottom(theme, productCardSize)
      : ''}
  `,
);

export const SelectButton = styled(Button)`
  width: auto;
  max-width: 100%;

  user-select: none;
  ${({ theme }) => theme.mq.mega} {
    user-select: auto;
    min-width: 180px;
  }
`;

export const StyledButtonWrapperLink = styled(ExternalLink)`
  display: flex;
  flex-basis: 280px;
`;
export const InstallmentsPrice = styled(Headline)`
  color: var(--cui-fg-accent);
`;

export const StrikeThroughInstallmentsPrice = styled(Body)(
  ({ theme }) => css`
    text-decoration: line-through;
    color: var(--cui-fg-subtle);

    user-select: none;
    ${theme.mq.mega} {
      user-select: auto;
    }
  `,
);

const ImageRatio = styled('div')(
  () => css`
    aspect-ratio: 1/1;
    position: relative;
    width: 100%;
  `,
);

const MoreInfoCTA = styled(InternalLink)<{ isODLExperimentEnabled?: boolean }>(
  ({ theme, isODLExperimentEnabled = false }) => css`
    display: block;
    width: 100%;
    margin-top: ${theme.spacings.mega};
    ${!isODLExperimentEnabled && `color: var(--cui-fg-accent);`}
  `,
);

const CTAsWrapper = styled('div')(
  () => css`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
  `,
);

const StyledBadge = styled(DiscountBadge)(
  ({ theme }) => css`
    position: absolute;
    top: ${theme.spacings.byte};
    left: ${theme.spacings.byte};
    margin: 0;
    font-size: 11px;
    padding: 0 7px;
    ${theme.mq.giga} {
      padding: 2px 8px;
      ${theme.typography.body.two};
    }
  `,
);

const ProductContent = styled('div')(
  ({
    theme,
    isSelected = false,
  }: {
    theme?: Theme;
    isSelected?: boolean;
  }) => css`
    flex: 1 0 auto;
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: ${theme.spacings.byte} ${theme.spacings.byte}
      ${theme.spacings.mega};
    border: ${theme.borderWidth.kilo} solid var(--cui-border-subtle);
    border-top: none;
    border-bottom-left-radius: ${theme.borderRadius.mega};
    border-bottom-right-radius: ${theme.borderRadius.mega};

    ${isSelected &&
    `border-color: var(--cui-border-accent); border-width: ${theme.borderWidth.mega};`}

    ${theme.mq.mega} {
      padding-left: ${theme.spacings.mega};
      padding-right: ${theme.spacings.mega};
      padding-bottom: ${theme.spacings.giga};
    }
  `,
);

const PriceWrapper = styled('div')(
  () => css`
    margin-top: auto;
  `,
);

const StyledInstallmentPrice = styled(InstallmentsPrice)(
  ({ theme }) =>
    css`
      color: var(--cui-fg-normal);
      ${theme.typography.headline.two};

      ${theme.mq.giga} {
        ${theme.typography.headline.one};
      }
    `,
);

const StyledOutOfStockBadge = styled(OutOfStockBadge)(
  ({ theme }) => css`
    position: absolute;
    top: ${theme.spacings.byte};
    left: ${theme.spacings.byte};
    margin: 0;
    font-size: 11px;
    padding: 0 7px;
    ${theme.mq.giga} {
      padding: 2px 8px;
      ${theme.typography.body.two};
    }
  `,
);

const InstallmentsPriceInfo = styled(Body)(
  ({ theme }) => css`
    font-size: 12px;
    line-height: 1.2;
    ${theme.mq.kilo} {
      ${theme.typography.body.two};
    }

    ${theme.mq.mega} {
      ${theme.typography.body.one}
    }
  `,
);

const RelativeDiv = styled('div')(
  ({ theme }) =>
    css`
      position: relative;
      margin-bottom: ${theme.spacings.byte};
      height: 100%;
      display: flex;
      flex-direction: column;
      cursor: pointer;
      ${theme.mq.mega} {
        margin-bottom: ${theme.spacings.mega};
      }
    `,
);

const NameLink = styled.a`
  text-decoration: none;
  color: var(--cui-fg-normal);
`;

export interface ProductCardProps
  extends Partial<IProduct>,
    Partial<IDigitalProductDetails> {
  isSelected?: boolean;
  isLoading?: boolean;
  taxMessage?: string;
  content: IProductCardContent;
  onSelect?: (sku: string, type: IProductType, id: string) => void;
  couponPromotion?: Promotion;
  discountRate?: number;
  numberOfInstallments?: number;
  installmentsFeeInfo?: string;
  fullPriceInfo?: string;
  formattedInstallmentAmount?: string;
  formattedInstallmentOriginalAmount?: string;
  isDigitalProduct?: boolean;
  /**
   * Product card with installment information has longer height.
   * To balance price position, we vertically centralize price
   * information when there is at least one product has installments.
   */
  isInstallmentsLayout?: boolean;
  orderOverviewTitle?: string;
  shortBenefits?: string;
  productsCount: number;
  isDiscountPriceEnabled?: boolean;
  stockQuantity?: number | null;
  isCtaToCheckoutEnabled?: boolean;
  addToCartText?: string;
  isDashboardShop?: boolean;
}

export const ProductCard = ({
  id,
  sku,
  type,
  trackingId,
  name,
  couponPromotion,
  formattedUnitAmount,
  formattedTotalAmount,
  discountRate,
  productContent,
  isSelected,
  onSelect,
  content,
  isLoading,
  slug,
  linkToDigitalProduct,
  numberOfInstallments,
  installmentsFeeInfo,
  fullPriceInfo,
  formattedInstallmentAmount,
  formattedInstallmentOriginalAmount,
  selectProductLabel,
  selectedProductLabel,
  isDigitalProduct,
  isInstallmentsLayout = false,
  productsCount,
  isDiscountPriceEnabled = false,
  stockQuantity = null,
  isCtaToCheckoutEnabled = true,
  shortBenefits,
  addToCartText,
  orderOverviewTitle,
  isDashboardShop = false,
  taxMessage,
}: ProductCardProps): JSX.Element => {
  const linkRef = useRef<HTMLAnchorElement>();
  const { query, locale } = useRouter();
  const countryCode = getCountryCodeFromISOLocale(locale);
  const { imageSrc, imageAltText } = productContent;

  const isODLExperimentEnabled = query[ODL_EXPERIMENT.id] === VARIATION;

  const userId = getUserId();
  const {
    callToActionText: pageLevelSelectLabel,
    selectedCallToActionText: pageLevelSelectedLabel,
    moreInfoText,
    discountBadgeText,
    loadingText,
    freeBadgeText,
    outOfStockText,
  } = content;

  const selectLabel = parseTranslation(
    selectProductLabel || pageLevelSelectLabel,
    {
      product: name,
    },
  );
  const selectedLabel = selectedProductLabel || pageLevelSelectedLabel;
  const ctaLabel = isSelected ? selectedLabel : selectLabel;
  const isOutOfStock = stockQuantity !== null && stockQuantity <= 0;
  const hasDiscount = discountRate > 0 && !isDigitalProduct;
  const isSelectionDisabled =
    isSelected ||
    isLoading ||
    (!formattedTotalAmount && !isDigitalProduct) ||
    isOutOfStock;
  const hasInstallments = numberOfInstallments > 1;
  const IMAGE_SIZE = Math.floor(1.5 * DEFAULT_IMAGE_SIZE);

  return (
    <>
      <RelativeDiv
        onClick={(e) => {
          if (e.target !== linkRef.current) {
            linkRef?.current?.click();
          }
        }}
      >
        <ImageWrapper isSelected={isSelected}>
          <ImageRatio>
            <Image
              src={getResizedUrl(imageSrc, IMAGE_SIZE, IMAGE_SIZE)}
              alt={imageAltText}
              width={DEFAULT_IMAGE_SIZE}
              height={DEFAULT_IMAGE_SIZE}
              priority
              layout="fill"
            />
          </ImageRatio>
          {isDiscountPriceEnabled && hasDiscount && !isOutOfStock && (
            <StyledBadge
              variant="promo"
              data-testid={`PRODUCT_CARD.${slug}.DISCOUNT_BADGE`}
            >
              {getDiscountDisplayValue(discountRate * 100)}
              {discountBadgeText}
            </StyledBadge>
          )}

          {isOutOfStock && (
            <StyledOutOfStockBadge
              isOutOfStock={isOutOfStock}
              data-testid="out-of-stock-badge"
            >
              {outOfStockText}
            </StyledOutOfStockBadge>
          )}
        </ImageWrapper>

        <ProductContent isSelected={isSelected}>
          <ProductName productsCount={productsCount}>
            <NextLink
              href={{
                pathname: getShopExperienceLink(
                  '/product-selection/[productName]',
                ),
                query: { ...query, productName: slug },
              }}
            >
              <NameLink
                ref={linkRef}
                aria-describedby={slug}
                onClick={() =>
                  dispatchMoreInfoClick({
                    from: 'image',
                    userId,
                    productName: name,
                  })
                }
              >
                {name}
              </NameLink>
            </NextLink>
          </ProductName>

          <ShortBenefits shortBenefits={shortBenefits} />
          <PriceWrapper>
            {!isDigitalProduct && (
              <>
                {hasInstallments ? (
                  <div css={spacing({ top: 'byte' })}>
                    {isDiscountPriceEnabled && hasDiscount && (
                      <StrikeThroughInstallmentsPrice variant="subtle">
                        {`${numberOfInstallments}x ${formattedInstallmentOriginalAmount}`}
                      </StrikeThroughInstallmentsPrice>
                    )}
                    <PriceContainer isPrimaryColor>
                      <InstallmentsPriceInfo
                        size="two"
                        css={spacing({ right: 'bit' })}
                      >
                        {numberOfInstallments}x
                      </InstallmentsPriceInfo>
                      <StyledInstallmentPrice size="one" as="h2">
                        {formattedInstallmentAmount}
                      </StyledInstallmentPrice>
                      <InstallmentsPriceInfo
                        size="two"
                        css={spacing({ left: 'bit' })}
                      >
                        {installmentsFeeInfo}
                      </InstallmentsPriceInfo>
                    </PriceContainer>
                    <Body
                      size="two"
                      variant="subtle"
                      css={spacing({ top: 'bit', bottom: 'byte' })}
                    >
                      {`${fullPriceInfo} ${formattedTotalAmount}`}
                    </Body>
                  </div>
                ) : (
                  <>
                    <PriceContainer>
                      <PriceComponent>{formattedTotalAmount}</PriceComponent>

                      {isDiscountPriceEnabled && hasDiscount && (
                        <StrikeThroughPrice>
                          {formattedUnitAmount}
                        </StrikeThroughPrice>
                      )}
                    </PriceContainer>
                    <TaxMessage taxMessage={taxMessage} light />
                  </>
                )}
              </>
            )}
            {isDigitalProduct && (
              <PriceContainer isVerticallyCenter={isInstallmentsLayout}>
                <PriceComponent isFree data-testid="freeBadge">
                  {freeBadgeText}
                </PriceComponent>
              </PriceContainer>
            )}
          </PriceWrapper>
        </ProductContent>
      </RelativeDiv>
      <CTAsWrapper>
        {isCtaToCheckoutEnabled && (
          <>
            <AddToCartButton
              href={isDigitalProduct ? linkToDigitalProduct : null}
              product={{
                id,
                sku,
                type,
                productContent,
                trackingId,
                name,
                productTitle: name,
              }}
              countryCode={countryCode}
              couponCode={couponPromotion?.couponCode}
              loadingLabel={loadingText}
              isLoading={isLoading}
              addToCartText={addToCartText}
              data-testid={`product_card.${trackingId}.CALL_TO_ACTION`}
              orderOverviewTitle={orderOverviewTitle}
              isDigitalProduct={isDigitalProduct}
              onSelect={onSelect}
              center
            />
          </>
        )}

        {!isCtaToCheckoutEnabled && (
          <SelectButton
            href={isDigitalProduct ? linkToDigitalProduct : null}
            loadingLabel={loadingText}
            isLoading={isLoading}
            data-testid={`product_card.${trackingId}.CALL_TO_ACTION`}
            disabled={isSelectionDisabled}
            onClick={() => {
              isDigitalProduct &&
                dispatchSkipShopEvent({ label: name, userId });
              onSelect && onSelect(sku, type, id);
            }}
            variant="primary"
          >
            {isDashboardShop ? addToCartText : ctaLabel}
          </SelectButton>
        )}

        <MoreInfoCTA
          isODLExperimentEnabled={isODLExperimentEnabled}
          id={slug}
          aria-hidden="true"
          data-testid={`product_card.${trackingId}.MORE_INFO`}
          href={{
            pathname: getShopExperienceLink('/product-selection/[productName]'),
            query: { ...query, productName: slug },
          }}
          onClick={() =>
            dispatchMoreInfoClick({
              from: 'button',
              userId,
              productName: name,
            })
          }
        >
          {moreInfoText}
        </MoreInfoCTA>
      </CTAsWrapper>
    </>
  );
};
