import { useEffect, useState } from 'react';

import { getItem } from 'shared/infra/storage';

function useFeatureToggleCookie(cookieName: string, initialValue: boolean) {
  const [cookieValue, setCookieValue] = useState(initialValue);

  useEffect(() => {
    const cookieValue = getItem(cookieName);
    if (!cookieValue) {
      return;
    }
    const valueAsBoolean = JSON.parse(cookieValue);
    setCookieValue(valueAsBoolean);
  }, [cookieName]);

  return cookieValue;
}

export default useFeatureToggleCookie;
