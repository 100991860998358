import { Theme } from '@sumup/design-tokens';

import { ProductCardSizes } from 'productSelection/components/ProductCard/ProductCard.types';

export type Spacing = keyof Theme['spacings'];

/**
 * GetMarginBottom provides 2 ways to assign margin bottom.
 * Consumers could assign spacing value from Circuit UI directly
 * or they could give product card sizes when using this
 * function in product card.
 */
export const getMarginBottom = (
  theme: Theme,
  size: ProductCardSizes | Spacing,
): string => {
  if (isProductCardSizes(size)) {
    if (
      size === ProductCardSizes.large ||
      size === ProductCardSizes.extraLarge
    ) {
      return `margin-bottom: ${theme.spacings.kilo}`;
    }
  } else {
    return `margin-bottom: ${theme.spacings[size]}`;
  }

  return '';
};

function isProductCardSizes(
  size: ProductCardSizes | Spacing,
): size is ProductCardSizes {
  return size in ProductCardSizes;
}
