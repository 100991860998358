import { Order } from '@commercelayer/sdk';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import * as clOrderAPI from 'shared/infra/commerceLayer/orders';
import {
  processCouponCodeToOrder,
  translateOrderToStore,
} from 'shared/infra/commerceLayer/utils';
import {
  dispatchInputErrorEvent,
  dispatchVoucherAppliedEvent,
} from 'shared/services/tracker/events';
import {
  applyCoupon,
  applyCouponFailure,
  applyCouponSuccess,
  resetCoupon as resetCouponAction,
} from 'shared/store/order/actions';

export const useCoupon = () => {
  const dispatch = useDispatch();

  const resetCoupon = () => {
    dispatch(resetCouponAction());
  };

  const addCoupon = useCallback(
    async (code: string, orderID: string): Promise<Order> => {
      try {
        // TODO: this dispatch only sets the state to
        // loading. We must revisit our loading strategy.
        dispatch(applyCoupon(code));

        const updatedOrder = await clOrderAPI.addCoupon(
          orderID,
          processCouponCodeToOrder(code),
        );

        dispatch(applyCouponSuccess(translateOrderToStore(updatedOrder)));
        dispatchVoucherAppliedEvent({ couponCode: code });

        return updatedOrder;
      } catch (err) {
        dispatch(applyCouponFailure('error applying coupon code'));
        dispatchInputErrorEvent({ errorField: 'voucher' });
      }
    },
    [dispatch],
  );

  return { addCoupon, resetCoupon };
};
