import { useEffect, useMemo } from 'react';

import { useTypedSelector } from 'shared/store';
import { getPromotionPRCs } from 'productSelection/services/promotions';
import { ISkuPriceData } from 'shared/infra/storefront/markets/prices';
import { useCoupon } from 'checkout/hooks/useCoupon';

export const usePromotionPRCs = (
  prices: Record<string, ISkuPriceData>,
): void => {
  const { addCoupon } = useCoupon();
  const prc = useMemo(() => getPromotionPRCs(), []);
  const { orderDetails, products: orderedLineItems } = useTypedSelector(
    (state) => state.order,
  );
  const { id: orderId, couponCode: orderCouponCode } = orderDetails;

  // sync ordered line item promotions with prc params
  useEffect(() => {
    const skusOnOrder = orderedLineItems.map((lineItem) => lineItem.skuCode);
    // attach the coupon code
    if (orderId && !orderCouponCode) {
      skusOnOrder.forEach((sku) => {
        const priceBySku = Object.values(prices).find(
          (priceInfo) => priceInfo.skuCode === sku,
        );
        const applicableCoupon = priceBySku?.appliedCouponCode;

        if (applicableCoupon) {
          addCoupon(applicableCoupon, orderId);
        }
      });
    }

    // clear the coupon code
    if (orderCouponCode) {
      const availablePromotions = Object.values(prices).reduce<string[]>(
        (acc, priceInfo) =>
          priceInfo.appliedCouponCode
            ? [...acc, priceInfo.appliedCouponCode]
            : acc,
        [],
      );

      if (
        !availablePromotions.includes(orderCouponCode) ||
        !prc.includes(orderCouponCode)
      ) {
        addCoupon('', orderId);
      }
    }
  }, [addCoupon, prc, orderCouponCode, orderId, orderedLineItems, prices]);
};
