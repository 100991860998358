import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Body } from '@sumup/circuit-ui';
import { Theme } from '@sumup/design-tokens';

type Props = {
  isFree?: boolean;
  theme?: Theme;
};

const base = ({ theme }: Props) => css`
  margin: ${theme.spacings.byte} 0 0;
  ${theme.typography.headline.two};
  font-weight: ${theme.fontWeight.bold};

  ${theme.mq.giga} {
    ${theme.typography.headline.one};
  }
`;

const free = ({ isFree }: Props) => css`
  ${isFree && `color: var(--cui-border-success);`}
`;

export const PriceComponent = styled(Body)(base, free);
