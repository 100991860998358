import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Theme } from '@sumup/design-tokens';
import { Headline } from '@sumup/circuit-ui';
import { FC, ReactNode } from 'react';

type Props = {
  theme?: Theme;
  productsCount: number;
  children?: ReactNode;
};

const base = ({ theme, productsCount }: Props) => css`
  width: 100%;
  user-select: none;
  margin-top: 0;
  ${theme.typography.body.two};

  ${theme.mq.mega} {
    margin-bottom: ${theme.spacings.bit};
  }

  ${theme.mq.kilo} {
    ${theme.typography.headline.three};
  }

  ${theme.mq.mega} {
    height: auto;
    user-select: auto;
  }

  ${theme.mq.giga} {
    ${productsCount !== 4
      ? theme.typography.headline.two
      : theme.typography.headline.three};
    margin-top: ${theme.spacings.mega};
    display: block;
  }
`;

const StyledProductName = styled(Headline)(base);

export const ProductName: FC<Props> = ({ children, productsCount }) => {
  return (
    <StyledProductName productsCount={productsCount} as="h2">
      {children}
    </StyledProductName>
  );
};
