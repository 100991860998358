import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Badge, Body, Headline } from '@sumup/circuit-ui';
import { Checkmark, Close } from '@sumup/icons';
import { useEffect } from 'react';

import {
  dispatchBusinessAccountSelectedEvent,
  dispatchBusinessAccountUnselectedEvent,
} from 'shared/services/tracker';

interface BusinessAccountSelectorProps {
  selectedCard: string;
  setSelectedCard: Function;
  accessedFromPDP?: boolean;
}

export const CARD_OPTIONS = {
  withBusinessAccount: 'with-business-account',
  withoutBusinessAccount: 'without-business-account',
};

const CardWrapper = styled.ul(
  ({ theme }) => css`
    list-style: none;
    display: flex;
    gap: ${theme.spacings.giga};
    margin: ${theme.spacings.giga} 0;
    overflow-x: auto;

    &::-webkit-scrollbar {
      display: none;
    }
  `,
);

const StyledListItem = styled.li`
  margin: 1px;
`;

const StyledRadioInput = styled.input(
  ({ theme }) => css`
    position: absolute;
    margin: 0 !important;
    padding: 0 !important;
    opacity: 0;
    height: 0;
    width: 0;
    pointer-events: none;

    &:checked + label {
      border: ${theme.borderWidth.mega} solid #3064e3;
    }
    &:focus + label {
      outline: ${theme.borderWidth.kilo} solid #3064e3;
    }
  `,
);

const Card = styled.label(
  ({ theme }) => css`
    display: block;
    width: 220px;
    border: ${theme.borderWidth.mega} solid var(--cui-border-normal);
    border-radius: ${theme.borderRadius.mega};
    padding: ${theme.spacings.kilo};
    cursor: pointer;
    height: 100%;
  `,
);

const CardHeadline = styled(Headline)(
  ({ theme }) => css`
    font-size: ${theme.typography.body.one.fontSize};
  `,
);

const BadgeWrapper = styled.div(
  ({ theme }) => css`
    margin-top: ${theme.spacings.byte};
    margin-bottom: ${theme.spacings.mega};
    height: 24px;
  `,
);

const FeeWrapper = styled.p(
  ({ theme }) => css`
    ${theme.typography.headline.two};
    margin: ${theme.spacings.byte} 0;
  `,
);

const OldFee = styled.span`
  color: var(--cui-fg-subtle-hovered);
  text-decoration: line-through;
`;

const NewFee = styled.span(
  ({ theme }) => css`
    color: var(--cui-border-success);
    font-weight: ${theme.fontWeight.bold};
    margin-left: ${theme.spacings.byte};
  `,
);

const CurrentFee = styled.span(
  ({ theme }) => css`
    color: var(--cui-fg-subtle-hovered);
    font-weight: ${theme.fontWeight.bold};
  `,
);

const BenefitGrid = styled.div(
  ({ theme }) => css`
    display: grid;
    grid-template-columns: ${theme.spacings.mega} 1fr;
    grid-column-gap: ${theme.spacings.byte};
    grid-row-gap: ${theme.spacings.mega};
    color: var(--cui-fg-subtle);
    margin-top: ${theme.spacings.giga};
  `,
);

export const PDP_LABEL = 'product_details';
export const BA_LEARN_MORE_LABEL = 'ba_learn_more';

export function BusinessAccountSelector({
  selectedCard,
  setSelectedCard,
  accessedFromPDP,
}: BusinessAccountSelectorProps) {
  const label = accessedFromPDP ? PDP_LABEL : BA_LEARN_MORE_LABEL;
  const handleSelectOfferFee = () => {
    setSelectedCard(CARD_OPTIONS.withBusinessAccount);
    dispatchBusinessAccountSelectedEvent({ label });
  };

  const handleSelectRegularFee = () => {
    setSelectedCard(CARD_OPTIONS.withoutBusinessAccount);
    dispatchBusinessAccountUnselectedEvent({ label });
  };

  useEffect(() => {
    if (selectedCard === CARD_OPTIONS.withBusinessAccount) {
      dispatchBusinessAccountSelectedEvent({ label });
    } else {
      dispatchBusinessAccountUnselectedEvent({ label });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CardWrapper>
      <StyledListItem>
        <StyledRadioInput
          id={CARD_OPTIONS.withBusinessAccount}
          type="radio"
          name="radio-button"
          checked={selectedCard === CARD_OPTIONS.withBusinessAccount}
          onChange={handleSelectOfferFee}
        />
        <Card htmlFor={CARD_OPTIONS.withBusinessAccount}>
          <CardHeadline size="four" as="h3">
            SumUp Business Account
          </CardHeadline>
          <BadgeWrapper>
            <Badge variant="confirm">Better value</Badge>
          </BadgeWrapper>
          <Body size="two" variant="subtle">
            Card reader fee
          </Body>
          <FeeWrapper>
            <OldFee>1.69%</OldFee>
            <NewFee>1.49%</NewFee>
          </FeeWrapper>
          <BenefitGrid>
            <div>
              <Checkmark />
            </div>
            <Body size="two" variant="subtle">
              Free current account
            </Body>
            <div>
              <Checkmark />
            </div>
            <Body size="two" variant="subtle">
              Free business Mastercard
            </Body>
            <div>
              <Checkmark />
            </div>
            <Body size="two" variant="subtle">
              Access to sales in{' '}
              <Body size="two" variant="highlight">
                1 day
              </Body>
            </Body>
          </BenefitGrid>
        </Card>
      </StyledListItem>
      <StyledListItem>
        <StyledRadioInput
          id={CARD_OPTIONS.withoutBusinessAccount}
          type="radio"
          name="radio-button"
          checked={selectedCard === CARD_OPTIONS.withoutBusinessAccount}
          onChange={handleSelectRegularFee}
        />
        <Card htmlFor={CARD_OPTIONS.withoutBusinessAccount}>
          <CardHeadline size="four" as="h3">
            Your own bank account
          </CardHeadline>
          <BadgeWrapper />
          <Body size="two" variant="subtle">
            Card reader fee
          </Body>
          <FeeWrapper>
            <CurrentFee>1.69%</CurrentFee>
          </FeeWrapper>
          <BenefitGrid>
            <div>
              <Close size="16" />
            </div>
            <Body size="two" variant="subtle">
              Free current account
            </Body>
            <div>
              <Close size="16" />
            </div>
            <Body size="two" variant="subtle">
              Free business Mastercard
            </Body>
            <div>
              <Checkmark />
            </div>
            <Body size="two" variant="subtle">
              Access to sales in{' '}
              <Body size="two" variant="highlight">
                2-3 business days
              </Body>
            </Body>
          </BenefitGrid>
        </Card>
      </StyledListItem>
    </CardWrapper>
  );
}
