import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { Body } from '@sumup/circuit-ui';
import { Theme } from '@sumup/design-tokens';
import { FC } from 'react';

type StyleProps = {
  theme?: Theme;
};

const base = ({ theme }: StyleProps) => css`
  font-size: 13px;
  margin: 0;

  ${theme.mq.mega} {
    ${theme.typography.body.two};
    margin-bottom: ${theme.spacings.byte};
  }
`;

const BenefitsContent = styled(Body)(base);

type Props = {
  shortBenefits?: string;
};

export const ShortBenefits: FC<Props> = ({ shortBenefits }) => {
  return (
    <BenefitsContent size="two">
      {shortBenefits}
    </BenefitsContent>
  );
};
