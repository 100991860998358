import { FC, useCallback, useEffect, useState } from 'react';
import { ButtonProps } from '@sumup/circuit-ui';
import { useRouter } from 'next/router';
import { useAuth } from '@sumup/react-nanoauth';
import styled from '@emotion/styled';
import { css } from '@emotion/react';

import { Button } from 'src/experiments/odl/components/Button';
import {
  IDigitalProductDetails,
  IProductDetails,
  IProductType,
} from 'productSelection/types/products';
import { redirectToSSOSignup } from 'shared/sso/RedirectService';
import { useShopExperience } from 'shared/context';
import { useTypedSelector } from 'shared/store';
import { getOrderIFrameUrl } from 'src/experiments/skip-shop/create-order/pages';
import { getShopExperienceLink } from 'shared/utils/shop-experience-link';
import { ShopExperience } from 'shared/constants/ShopExperience';
import {
  dispatchGoToCheckoutEvent,
  dispatchSkipShopEvent,
} from 'shared/services/tracker';

export interface AddToCartButtonProps
  extends Omit<ButtonProps, 'onSelect' | 'children'> {
  countryCode: string;
  product: IProductDetails | IDigitalProductDetails;
  isLoading?: boolean;
  couponCode?: string;
  addToCartText?: string;
  loadingLabel?: string;
  marketReference?: string;
  commerceLayerClient?: string;
  orderOverviewTitle?: string;
  onSelect?: (
    sku: string,
    type: IProductType,
    id: string,
    couponCode?: string,
  ) => void;
  isDigitalProduct: boolean;
  center?: boolean;
}

const StyledButton = styled(Button)<{ center?: boolean }>(
  ({ theme, center = false }) => css`
    user-select: none;
    width: 100%;
    ${center && `max-width: 170px; margin: 0 auto;`}

    ${theme.mq.mega} {
      user-select: auto;
      max-width: 170px;
    }
  `,
);

export const AddToCartButton: FC<AddToCartButtonProps> = ({
  product,
  isLoading = false,
  addToCartText = 'Add to cart',
  loadingLabel = 'Loading...',
  orderOverviewTitle,
  onSelect,
  isDigitalProduct,
  center = false,
  ...props
}) => {
  const [isClicked, setClicked] = useState(false);
  const { push, locale, query } = useRouter();
  const { authRedirect, authState } = useAuth();
  const source = useShopExperience();
  const { orderDetails } = useTypedSelector((state) => state.order);

  const handleProductSelection = useCallback(() => {
    if (isDigitalProduct) {
      dispatchSkipShopEvent({ label: product.name });
      return;
    }
    onSelect && onSelect(product.sku, product.type, product.id);
    setClicked(true);
    dispatchGoToCheckoutEvent({ shopExperience: ShopExperience.SIGNUP });
  }, [
    onSelect,
    product.id,
    product.type,
    product.sku,
    isDigitalProduct,
    product.name,
  ]);

  useEffect(() => {
    if (!isLoading && isClicked) {
      const requestParams = {
        ...{
          iframe_uri: getOrderIFrameUrl(orderDetails.id, locale),
          iframe_title: orderOverviewTitle,
        },
      };

      redirectToSSOSignup({
        query,
        authRedirect,
        authState,
        locale,
        push,
        redirectPathname: getShopExperienceLink(`/checkout/address`),
        source,
        requestParams,
      });
    }
  }, [
    authRedirect,
    authState,
    isClicked,
    isLoading,
    locale,
    push,
    query,
    source,
    orderDetails,
    orderOverviewTitle,
  ]);

  return (
    <StyledButton
      loadingLabel={loadingLabel}
      isLoading={isLoading || isClicked}
      variant="primary"
      center={center}
      onClick={handleProductSelection}
      {...props}
    >
      {addToCartText}
    </StyledButton>
  );
};
