import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Theme } from '@sumup/design-tokens';

type Props = {
  isSelected?: boolean;
  theme?: Theme;
};

const base = ({ theme, isSelected }: Props) => css`
  background-color: var(--cui-bg-subtle);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border: ${theme.borderWidth.mega} solid transparent;
  border-top-left-radius: ${theme.borderRadius.mega};
  border-top-right-radius: ${theme.borderRadius.mega};
  border-bottom: none;

  ${isSelected && `border-color: var(--cui-border-accent);`}
`;

export const ImageWrapper = styled('div')<Props>(base);
